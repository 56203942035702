
import { defineComponent } from "vue";
import ListItemList from "@/components/ListItemList.vue";
import { item } from "@/types";
export default defineComponent({
  setup() {
    function filter(item: item) {
      return !item.finishDate;
    }

    return { filter };
  },
  components: {
    ListItemList,
  },
});
